<template>
  <div class="mcr-poems-table">
    <div v-for="row in rowsItemsData" class="row" :style="styles">
      <mcr-poem
        v-for="item in row"
        :key="item.number"
        :number="item.number"
        :symbol="item.symbol"
        :pinyin="item.pinyin"
        :meaning="item.meaning"
      ></mcr-poem>
    </div>
  </div>
</template>

<script>
import McrPoem from '@/components/modules/familyWebsite/components/McrPoem';

export default {
  props: {
    columnsNumber: String,
    items: String,
  },
  data() {
    const itemsData = JSON.parse(this.items);
    return {
      itemsData: itemsData,
    };
  },
  computed: {
    columnsNumberInt() {
      return parseInt(this.columnsNumber);
    },
    styles() {
      return {'grid-template-columns': `repeat(${this.calculatedColumns}, 1fr)`};
    },
    calculatedColumns() {
      if (this.$store.getters.windowWidthState <= this.$breakpoints.phablet) {
        return 2;
      }
      if (this.$store.getters.windowWidthState <= this.$breakpoints.mobile) {
        return 3;
      }
      return isNaN(this.columnsNumberInt) ? 5 : this.columnsNumberInt;
    },
    rowsItemsData() {
      const rows = [];
      let i = 0;
      while (i < this.itemsData.length) {
        rows.push(this.itemsData.slice(i, i + this.columnsNumberInt));
        i += this.columnsNumberInt;
      }

      return rows;
    },
  },
  components: {McrPoem},
};
</script>

<style lang="scss" scoped>
.mcr-poems-table {
  display: flex;
  flex-direction: column;

  .row {
    display: grid;
    .mcr-poem {
      margin-bottom: 20px;
    }
    @media only screen and (max-width: $breakpoint-mobile) {
      margin-bottom: 50px;
    }
  }
}
</style>
